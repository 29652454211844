<template>
  <div>
    <vs-prompt
      :accept-text="$t('Accept')"
      :cancel-text="$t('Cancel')"
      :title="$t('Design_Message_13')"
      @accept="openCreditPage"
      :active.sync="newOrderError"
      color="dark"
    >
      <div class="con-exemple-prompt">
        <span>{{ $t("Dental_Message_18") }}</span>
      </div>
    </vs-prompt>
    <!-- New Order Buton- Owner - Search -->
    <div class="flex vx-row justify-between mb-2">
      <vs-button class="xs:mb-2" icon="add" type="border" @click="newOrder">
        {{ $t("New Order") }}
      </vs-button>
      <div class="vx-row">
        <v-select
          class="searchAndSort sm:mr-2 xs:mr-1"
          v-model="selectedOwner"
          @input="getOrdersWithOwner()"
          placeholder="Owner"
          :options="ownerOption"
        >
        </v-select>

        <vs-input
          class="searchAndSort sm:ml-2"
          icon="search"
          icon-no-border
          size="medium"
          v-model="searchValue"
          @keypress.enter="searchOrder()"
        />
      </div>
    </div>
    <!-- Tablar -->
    <div class="vx-row">
      <!-- ALL -->
      <div class="vx-col tabAllNewProcessingReady sm:w-3/12 xs:w-2/12">
        <div
          :class="{ active: activeTab === 'A' }"
          @click="
            activeTab = 'A';
            getOrdersWithNewFilter();
          "
          class="tabTitle radius-left"
        >
          {{ $t("All").toUpperCase() }}
        </div>
      </div>
      <!-- NEW -->
      <div class="vx-col tabAllNewProcessingReady sm:w-3/12 xs:w-2/12">
        <div
          :class="{ active: activeTab === 'N,W' }"
          @click="
            activeTab = 'N,W';
            getOrdersWithNewFilter();
          "
          class="tabTitle"
        >
          {{ $t("New").toUpperCase() }}
        </div>
      </div>
      <!-- PROCESSING -->
      <div class="vx-col tabAllNewProcessingReady sm:w-3/12 xs:w-5/12">
        <div
          :class="{ active: activeTab === 'P' }"
          @click="
            activeTab = 'P';
            getOrdersWithNewFilter();
          "
          class="tabTitle"
        >
          {{ $t("Processing").toUpperCase() }}/{{ $t("Sent").toUpperCase() }}
        </div>
      </div>
      <!-- READY -->
      <div class="vx-col tabAllNewProcessingReady sm:w-3/12 xs:w-3/12">
        <div
          :class="{ active: activeTab === 'R' }"
          @click="
            activeTab = 'R';
            getOrdersWithNewFilter();
          "
          class="tabTitle radius-right"
        >
          {{ $t("Ready").toUpperCase() }}
        </div>
      </div>
    </div>

    <div class="scroolHorizontal">
      <!-- List Header -->
      <div class="flex">
        <div class="w-full flex">
          <div
            @click="handleSortTable('id')"
            class="tabHeader clickableHeaders orderIdWidth w-1/12 w-full"
          >
            {{ $t("No_Number") }}
          </div>
          <div class="tabHeader orderDateWidth w-3/12 w-full">
            {{ $t("Date") }}
          </div>
          <div
            @click="handleSortTable('patient')"
            class="tabHeader clickableHeaders orderPatientWidth w-4/12 w-full"
          >
            {{ $t("Patient") }}
          </div>
          <div class="tabHeader orderOwnerWidth w-4/12 w-full">
            {{ $t("Owner") }}
          </div>

          <div
            @click="handleSortTable('lab')"
            class="tabHeader clickableHeaders orderLabWidth w-5/12"
          >
            Lab
          </div>

          <div class="tabHeader orderStatusWidth w-4/12">
            {{ $t("Status") }}
          </div>
          <div class="tabHeader orderCreditWidth text-center w-1/12">
            {{ $t("Credit") }}
          </div>
          <div class="tabHeader orderButtonWidth w-2/12"></div>
        </div>
      </div>
      <!-- List -->
      <orderList
        class="orderBar"
        v-for="order in orders"
        :order="order"
        :key="order.id"
      />
    </div>

    <!-- Pagination -->
    <div class="m-2">
      <vs-pagination
        :max="7"
        :total="totalPage"
        v-model="currentPage"
        @change="handleChangePage(currentPage)"
      />
    </div>
  </div>
</template>

<script>
import Api from "@/api/api";
import API from "@/api/config";

import vSelect from "vue-select";

import orderList from "./components/OrderList";

export default {
  data() {
    return {
      newOrderError: false,
      selectedOwner: null,
      ownerOption: [],

      searchValue: "",
      searchActive: true,
      firstLoading: true,
      activeTab: "A",

      //Pagination
      currentPage: 1,
      totalPage: 1,

      ordering: "",
      orders: [],
    };
  },
  methods: {
    openCreditPage() {
      this.$router.push("/dental_credit").catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    },
    newOrder() {
      if (this.getCredit <= 0) {
        this.newOrderError = true;
      } else {
        this.$router.push("/dental_order").catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
      }
    },
    getOrdersWithOwner() {
      this.currentPage = 1;
      this.getOrders();
    },
    searchOrder() {
      if (this.searchActive) {
        this.searchActive = false;
        this.ordering = "";
        this.currentPage = 1;
        this.activeTab = "A";
        this.getOrders();
      }
    },
    getOrdersWithNewFilter() {
      this.ordering = "";
      this.currentPage = 1;
      this.getOrders();
    },
    handleSortTable(sortValue) {
      if (sortValue == this.ordering) {
        this.ordering = "-" + this.ordering;
      } else {
        this.ordering = sortValue;
      }

      this.currentPage = 1;
      this.getOrders();
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getOrders();
    },
    controlWaitingFileOrder() {
      for (let index = 0; index < this.orders.length; index++) {
        if (this.orders[index].order_status == "W") {
          this.$vs.notify({
            title: this.$t("Dental_Message_16"),
            text: this.$t("Dental_Message_17"),
            color: "warning",
          });
          return;
        }
      }
    },
    handleGetOrdersRequest(status, data) {
      this.searchActive = true;
      if (status == 200) {
        this.ownerOption = [];
        this.ownerOption[0] = {
          id: "",
          label: this.$t("All"),
        };
        if (data.owner_list != null) {
          data.owner_list.forEach((element, index) => {
            if (element.name != null && element.name != "") {
              this.ownerOption[index + 1] = {
                id: element.email,
                label: element.name,
              };
            } else {
              this.ownerOption[index + 1] = {
                id: element.email,
                label: element.email,
              };
            }
          });
        }

        this.orders = data.results;
        this.$store.dispatch("updateDentalCredit", data.dental_credit);
        this.totalPage = Math.ceil(data.count / 20);
        this.$vs.loading.close();
        if (this.firstLoading) {
          this.controlWaitingFileOrder();
          this.firstLoading = false;
        }
      }
    },
    getOrders() {
      var params = {};

      if (this.currentPage != 1) {
        params.page = this.currentPage;
      }

      if (this.searchValue != "") {
        params.search = this.searchValue;
      }

      if (this.activeTab != "A") {
        if (this.activeTab == "N,W") {
          params.order_status = "N,W";
        } else if (this.activeTab == "P") {
          params.order_status = "P,S";
        } else if (this.activeTab == "R") {
          params.order_status = "R";
        }
      }

      if (this.ordering != "") {
        params.ordering = this.ordering;
      }

      if (this.selectedOwner) {
        if (this.selectedOwner.id != "") params.owner = this.selectedOwner.id;
      }

      this.$router.push({ query: params }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });

      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });
      Api.get(
        API.BASEURL + API.DENTALORDERS,
        this.handleGetOrdersRequest,
        params
      );
    },
  },
  components: {
    vSelect,
    orderList,
  },
  computed: {
    getCredit() {
      return this.$store.getters.getDentalCredit;
    },
  },
  created() {
    if (this.$route.query.page != null && this.$route.query.page != "1") {
      this.currentPage = parseInt(this.$route.query.page);
    }
    if (this.$route.query.order_status != null) {
      this.activeTab = this.$route.query.order_status;
    }
    if (this.$route.query.ordering != null) {
      this.ordering = this.$route.query.ordering;
    }
    if (this.$route.query.search != null) {
      this.searchValue = this.$route.query.search;
    }
  },
};
</script>

<style lang="scss">
.orderBar {
  cursor: pointer;
  &:hover {
    transform: translateY(-4px);
    box-shadow: 0px 3px 10px 0px #ccc3;
    transition: all 0.5s;
  }
}
.scroolHorizontal {
  width: 100%;
  overflow: auto;
}
.orderIdWidth {
  min-width: 60px;
  max-width: 80px;
}
.orderDateWidth {
  min-width: 100px;
  max-width: 120px;
  :hover {
    overflow: visible;
    white-space: normal;
  }
}
.orderPatientWidth {
  min-width: 200px;
  :hover {
    overflow: visible;
    white-space: normal;
  }
}
.orderOwnerWidth {
  min-width: 260px;
}
.orderLabWidth {
  min-width: 150px;
}
.orderStatusWidth {
  min-width: 100px;
}
.orderCreditWidth {
  min-width: 70px;
}
.orderButtonWidth {
  min-width: 180px;
}

.v-select .vs__dropdown-toggle .vs__actions .vs__clear {
  display: none;
}

.vs__dropdown-toggle {
  background: white;
}

.vs__dropdown-option--highlight {
  background: #c89e82 !important;
  color: #fff;
}
.vs-input--icon {
  font-size: 1.5rem;
}
.vs-pagination--mb {
  justify-content: center !important;
}
</style>

<style lang="scss" scoped>
.radius-left {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.radius-right {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.tabTitle {
  margin-bottom: 5px;
  width: 100%;
  cursor: pointer;
  padding: 10px 15px;
  font-size: 0.8em;
  font-weight: 800;
  background-color: #fff;
  text-align: center;
}
.active {
  background: #c89e82 !important;
  color: white !important;
}
.tabAllNewProcessingReady {
  padding: 0 !important;
}
.vx-row {
  margin: 0;
}
.tabHeader {
  padding: 10px 15px;
  border: 0;
  text-align: left;
  font-size: 0.85rem;
  font-weight: 800;
  background-color: white;
}
.clickableHeaders {
  cursor: pointer;
}
@media (min-width: 601px) {
  .searchAndSort {
    min-width: 220px;
    max-width: 220px;
  }
}
@media (max-width: 601px) {
  .searchAndSort {
    min-width: auto;
    max-width: auto;
  }
}
@media (max-width: 651px) {
  .searchAndSort {
    min-width: 150px;
    max-width: 185px;
  }
}
.vs-button {
  padding: 0.679rem 1rem !important;
}
</style>
