<template>
  <div class="flex">
    <div class="vx-row w-full">
      <div class="flex w-full">
        <div
          class="valueBackground orderIdWidth pl-2 w-1/12 w-full"
          @click="openOldOrderPage(order)"
        >
          <p>{{ order.id }}</p>
        </div>
        <div
          class="valueBackground orderDateWidth w-3/12 w-full"
          @click="openOldOrderPage(order)"
        >
          <p class="orderDateP">
            {{ getRelativeTime(order.created) }}
          </p>
        </div>
        <div
          class="valueBackground orderPatientWidth p-2 w-4/12 w-full"
          @click="openOldOrderPage(order)"
        >
          <p class="orderPatientP">{{ order.patient }}</p>
        </div>
        <div
          class="valueBackground orderOwnerWidth p-2 w-4/12 w-full"
          @click="openOldOrderPage(order)"
        >
          <p class="orderOwnerP">{{ order.owner }}</p>
        </div>
        <div
          class="valueBackground orderLabWidth w-5/12"
          @click="openOldOrderPage(order)"
        >
          <p class="orderLabP">{{ order.lab_display }}</p>
        </div>
        <div
          class="valueBackground orderStatusWidth w-4/12"
          @click="openOldOrderPage(order)"
        >
          <vs-chip :color="getOrderStatusColor(order.order_status)">{{
            getOrderStatus(order.order_status)
          }}</vs-chip>
        </div>
        <div
          class="valueBackground orderCreditWidth text-center w-1/12"
          @click="openOldOrderPage(order)"
        >
          <p>{{ order.credit }}</p>
        </div>
        <div class="valueBackground orderButtonWidth w-2/12 text-center">
          <vs-button
            :color="downloadCancelButtonColor"
            :disabled="isDownloading"
            v-if="order.order_status == 'R'"
            @click="
              isDownloading = true;
              downloadOrderFile();
            "
            >{{ downloadCancelButtonTitle }}
          </vs-button>
          <vs-progress
            class="progressWidth"
            v-if="filePersent && filePersent != 100"
            :height="12"
            :percent="filePersent"
          ></vs-progress>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/api/api";
import API from "@/api/config";

import axios from "axios";
import moment from "moment";

// var request = axios.CancelToken.source();

export default {
  data() {
    return {
      downloadCancelButtonTitle: this.$t("Download"),
      downloadCancelButtonColor: "primary",
      isDownloading: false,
      filePersent: 0,
      request: axios.CancelToken.source(),
    };
  },
  destroyed() {
    this.requestCancel();
  },

  props: ["order"],
  methods: {
    getRelativeTime(value) {
      return moment(String(value)).format("DD/MM/YYYY - HH:mm");
    },
    getOrderStatus(status) {
      if (status == "S") {
        return this.$t("Sent");
      } else if (status == "P") {
        return this.$t("Processing");
      } else if (status == "R") {
        return this.$t("Ready");
      } else if (status == "W") {
        return this.$t("Waiting");
      } else if (status == "C") {
        return this.$t("Cancelled");
      } else {
        return this.$t("New");
      }
    },
    getOrderStatusColor(status) {
      if (status == "W") return "#389CA3";
      if (status == "P") return "warning";
      if (status == "R") return "success";
      if (status == "S") return "#31bdf5";
      if (status == "C") return "danger";
      return "primary";
    },
    downloadOrderFile() {
      if (this.downloadCancelButtonTitle == this.$t("Cancel")) {
        this.requestCancel();
        return;
      }
      this.downloadCancelButtonTitle = this.$t("Cancel");
      this.downloadCancelButtonColor = "danger";
      Api.get(
        API.BASEURL + API.DENTALORDERS + this.order.id + "/download/",
        this.handleDownloadFile
      );
    },
    handleDownloadFile(status, data) {
      if (status == 200) {
        this.isDownloading = false;
        if (data.plate_url != "") {
          this.request = axios.CancelToken.source();
          var downloadFileName = data.plate_url
            .toString()
            .split("plates/")[1]
            .split("?")[0];

          if (downloadFileName.split(".")[1] != "zip") {
            downloadFileName = this.order.id + "-" + downloadFileName;
          }

          axios({
            url: data.plate_url,
            method: "GET",
            cancelToken: this.request.token,
            responseType: "blob",
            onDownloadProgress: function (progressEvent) {
              this.filePersent = parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
            }.bind(this),
          })
            .then((response) => {
              this.downloadCancelButtonTitle = this.$t("Download");
              this.downloadCancelButtonColor = "primary";
              this.isDownloading = false;
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", downloadFileName);
              document.body.appendChild(link);
              link.click();
            })
            .catch((error) => {
              if (error.message == "Cancel") {
                this.filePersent = 0;
                this.isDownloading = false;
                this.downloadCancelButtonTitle = this.$t("Download");
                this.downloadCancelButtonColor = "primary";
              }
            });
        } else {
          this.isDownloading = false;
          this.downloadCancelButtonTitle = this.$t("Download");
          this.downloadCancelButtonColor = "primary";
          this.$vs.notify({
            title: this.$t("Warning"),
            text: this.$t("Error_Message_1"),
            color: "danger",
          });
        }
      } else if (status == 404) {
        this.isDownloading = false;
        this.downloadCancelButtonTitle = this.$t("Download");
        this.downloadCancelButtonColor = "primary";
        this.$vs.notify({
          title: this.$t("Warning"),
          text: this.$t("File_Message_1"),
          color: "danger",
        });
      } else if (status == 500) {
        this.isDownloading = true;
        this.downloadCancelButtonTitle = this.$t("Download");
        this.downloadCancelButtonColor = "primary";
        this.$vs.notify({
          title: this.$t("Warning"),
          text: this.$t("Error_Message_1") + this.$t("File_Message_1"),
          color: "danger",
        });
      }
    },
    openOldOrderPage(data) {
      var queryData = {
        id: data.id,
      };

      this.$router
        .push({
          name: "dental_order",
          query: queryData,
        })
        .catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
    },
    requestCancel() {
      this.request.cancel("Cancel");
      this.downloadCancelButtonTitle = this.$t("Download");
      this.downloadCancelButtonColor = "primary";
    },
  },
};
</script>

<style lang="scss" scoped>
.progressWidth {
  width: 130px;
}
.orderDateP {
  white-space: nowrap;
  width: 75px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.orderPatientP {
  white-space: nowrap;
  width: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.orderOwnerP {
  white-space: nowrap;
  width: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.orderLabP {
  white-space: nowrap;
  width: 135px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.vx-row {
  margin: 0;
}
.valueBackground {
  background-color: white;
  padding-top: 5px;
  padding-bottom: 5px;
  .p {
    font-size: 1rem;
  }
}
</style>
